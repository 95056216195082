@import "modules/variables.scss";
@import "modules/mixins.scss";

#greenly-link {
    cursor: pointer;
    position: absolute;
    top: 200px;
    z-index: 100;
    right: 0px;
    overflow: hidden;

    img {
        animation: greenly-link 1s forwards;
    }
}

@keyframes greenly-link {
    from {
        transform: translateX(120px);
    }

    to {
        transform: translateX(0);
    }
}
