@import "modules/variables.scss";
@import "modules/mixins.scss";

#rse-popup {
    background-color: #fff;
    position: relative;
    margin: 20vh auto;
    width: 536px;
    line-height: 17px;
    text-align: left;
    font-family: 'proxima-nova';

    @include onMobile {
        margin: 8vh auto;
        width: 90%;
    }

    @include onDesktopLittle {
        margin: 10vh auto;
    }

    @include onMobileLong {
        margin: 18vh auto;
    }

    @include onTabletOnly {
        max-height: 400px;
    }

    #rse-popup-close {
        background-color: #038096;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        left: 510px;
        top: -24px;
        cursor: pointer;
        float: left;

        @include onMobile {
            left: 79vw;
            top: -24px;
        }

        .close-icon {
            color: white;
            font-size: 36px;
            position: relative;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }

    }

    #rse-popup-header {
        background-image: url("/img/rse/header.png");
        background-size: cover;
        height: 193px;

        @include onMobile {
            height: 100px;
        }
    }

    #rse-popup-body {
        font-size: 13px;
        padding: 27px 30px;

        @include onMobile {
            padding: 10px 20px;
            font-size: 12px;
        }

        #rse-popup-body-title {
            color: #038096;
            font-size: 22px;
            text-align: center;
            line-height: 32px;
            padding-bottom: 15px;

            @include onMobile {
                padding-bottom: 0;
                font-size: 19px;
            }
        }

        p {
            padding: 15px 0 0 0;
        }

        #popup-signing {
            margin-top: 25px;
            display: flex;
            align-items: center;

            #signing-picture {
                width: 41px;
                height: 41px;
            }

            #signing-text {
                margin-left: 15px;

                #signing-name {
                    font-weight: bold;
                }

                #signing-job {
                    font-style: italic;
                }
            }
        }
    }

    #rse-popup-footer {
        background-color: #038096;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 120px;
        justify-content: space-evenly;
        font-weight: bold;

        .footer-content {
            font-size: 14px;

            @include onMobile {
                font-size: 13px;
        }
        }

        .footer-btn {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            background-color: $blueSynchrone;
            color: white;
            width: 240px;
            height: 40px;
            border-radius: 50px;
            text-align: center;
            line-height: 40px;
        }
    }
}